import React from "react";
import { Audio } from 'react-loader-spinner';
import styles from './Loader.module.scss';

const Loader: React.FC = () => {
  return (
    <div className={styles.loaderContainer}>

<Audio
  height="80"
  width="80"
  color="green"
  ariaLabel="loading"
  />
  </div>
  );
};

export default Loader;
